.user-profile {
  position: relative;
  display: inline-block;
}

.profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-picture {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.username {
  font-weight: bold;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 16px); /* Adjust to start below the top nav bar */
  right: 0px;
  width: 250px; /* Adjust the width of the dropdown menu */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, max-height 0.3s ease;
  max-height: 0;
  overflow: hidden;
}

.dropdown-menu.open {
  opacity: 1;
  transform: translateY(0);
  max-height: 500px; /* Adjust based on content */
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-section {
  border-top: 1px solid #e1e1e1;
  margin-top: 5px;
  padding-top: 5px;
}
