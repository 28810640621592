.loading-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -150px;
  height: 120vh;
  background-color: #e7f5ed; /* Same background color as your form */
  text-align: center;
}

.loader-text {
  font-size: 1em; /* Adjust the font size as needed */
  letter-spacing: 10.15em !important; /* Reduce space between letters */
  color: green; /* Change the color to match your theme if needed */
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}

.absolute {
  position: absolute;
}

.inline-block {
  display: inline-block;
}

.loader {
  display: flex;
  margin: 0.25em 0;
}

.w-2 {
  width: 0.5em;
}

.dash {
  animation: dashArray 2s ease-in-out infinite, dashOffset 3s linear infinite;
}

.spin {
  animation: spinDashArray 2s ease-in-out infinite, spin 8s ease-in-out infinite,
    dashOffset 2s linear infinite;
  transform-origin: center;
}

@keyframes dashArray {
  0% {
    stroke-dasharray: 0 1 359 0;
  }

  50% {
    stroke-dasharray: 0 359 1 0;
  }

  100% {
    stroke-dasharray: 359 1 0 0;
  }
}

@keyframes spinDashArray {
  0% {
    stroke-dasharray: 270 90;
  }

  50% {
    stroke-dasharray: 0 360;
  }

  100% {
    stroke-dasharray: 270 90;
  }
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: 365;
  }

  100% {
    stroke-dashoffset: 5;
  }
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }

  12.5%,
  25% {
    rotate: 270deg;
  }

  37.5%,
  50% {
    rotate: 540deg;
  }

  62.5%,
  75% {
    rotate: 810deg;
  }

  87.5%,
  100% {
    rotate: 1080deg;
  }
}

@keyframes wobbleA {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-50%) scale(1.2);
    opacity: 0.8;
  }
}

.letter-a {
  animation: wobbleA 2s infinite;
}
