/* CreateAdmin.css */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.modal-title {
  font-weight: bold;
  margin-top: 10px !important; /* Adds space below the close button */
  margin-bottom: 40px !important;
  text-align: left; /* Aligns the heading to the left */
  font-size: 1.1rem;
}

.create-admin-form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}

.form-group {
  display: contents;
}

.form-group label {
  display: block;
  /* margin-top: 10px; */
  width: 150px; /* Set a consistent width for labels */
  text-align: right; /* Right-align the labels */
  padding-right: 10px; /* Add some space between label and input */
  color: gray;
  align-self: center; /* Aligns labels vertically to the center */
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  align-self: center; /* Aligns inputs vertically to the center */
}

.form-actions {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-button,
.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button {
  background-color: #6c757d;
  color: #fff;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.submit-button {
  background-color: #28a745;
  color: #fff;
}

.submit-button:hover {
  background-color: #218838;
}
#Role {
  margin-top: 0px;
}
