/* src/Components/Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure the modal is on top of other elements */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: darkgreen;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

/* .modal-content button:hover {
  background-color: #0056b3;
} */

.modal-content button + button {
  margin-left: 10px;
}

.modal-content .close-button {
  position: absolute;
  top: -36px;
  right: -16px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: black; /* Ensure the close button is visible */
}
.capitalize {
  text-transform: capitalize;
  margin-bottom: 13px;
}

.modal-content select {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.ModalDisable {
  cursor: not-allowed !important;
  opacity: 0.6;
  pointer-events: none;
  background: gray !important;
  border-color: gray;
  color: black !important;
}
